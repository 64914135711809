import LocomotiveScroll from 'locomotive-scroll';
import { gsap, TweenLite, TweenMax, Linear, Back, Elastic, TimelineMax, TimelineLite, Power1, Power4 , Power2, Power3} from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { Draggable } from "gsap/Draggable";
import { SplitText } from "gsap/SplitText";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { CustomEase } from "gsap/CustomEase";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import barba from '@barba/core';
import 'slick-carousel';
gsap.registerPlugin(CSSRulePlugin, DrawSVGPlugin, Draggable, SplitText,ScrollToPlugin, ScrollTrigger, MorphSVGPlugin, CustomEase, InertiaPlugin, TweenMax);




(function( $ ) {

	let loading = true;

	function loadIntro(){
		gsap.fromTo($("#figure"),{y:"5%",opacity:0},{opacity:1, y:"0%", duration:2, delay:0,ease:Power3.easeOut});
		gsap.fromTo($("h5#intro-h5>div>div"),{y:"50%", opacity:0}, {y:"0%", duration:1.5, opacity:1, delay:0, ease:Power3.easeOut});
		gsap.fromTo($("h1#intro-h1>div>div"),{y:"50%", opacity:0}, {y:"0%", duration:1.5, opacity:1, delay:0, ease:Power3.easeOut});
		
		$("p#intro-p>div>div").each(function(i,v){
			var delay = 0.5+(i*0.1);
			gsap.fromTo($(v),{y:"100%", opacity:0}, {y:"0%", duration:1.5, opacity:1, delay:delay, ease:Power3.easeOut});
		});
	}
	

	function pageInit(){

		$("#page-content").addClass("-ready");
		
		
	
		setTimeout(function(){
			initSplitText();
			$("main").addClass("-ready");
			loadIntro();
			
		},500); 

		setTimeout(function(){
			gsap.to($("#site-logo, .hamburger-icon, .scroll-indicator-mouse"), {opacity:1, y:0,duration:1, delay:0, ease:Power3.easeInOut});
		},3000);


		//loaderAnimationTriangle();

		


		function loaderAnimationTriangle(){
			
			
			function outlineAnimation(){
				//gsap.to($("#triangle-outline-svg"), {duration: 4, delay:0, rotate:"-240deg",ease:Linear});
				gsap.set($("#triangle-outline-svg path"), {drawSVG:"0% 0%",ease:"none"});
				$.each($("#triangle-outline-svg path.r1"), function(i,v){
					
					gsap.to($(v), {drawSVG:"14% 54%",duration:1.5,delay:0,ease:Power2.easeIn});
					gsap.to($(v), {drawSVG:"86% 86%",duration:1.5,delay:1.5,ease:Power2.easeOut});
				});
				$.each($("#triangle-outline-svg path.r2"), function(i,v){
					
					gsap.to($(v), {drawSVG:"14% 54%",duration:1.5,delay:2,ease:Power2.easeIn});
					gsap.to($(v), {drawSVG:"86% 86%",duration:1.5,delay:3.5,ease:Power2.easeOut});
				});
				
				setTimeout(function(){
					//outlineAnimation();
				},4000);

				
			}

			outlineAnimation();
			

			
			
			
			


			
		}

		function loaderAnimationSides(){
			//gsap.to($("#loader .loader-container"), {rotate:"-60deg", duration:1.5,delay:0,ease: Power1.easeInOut,overwrite:true});
			gsap.to($("#loader #side-1 .i"), {right:"70vw", duration:2,delay:0,ease: Power3.easeInOut,overwrite:true});
			gsap.to($("#loader #side-2 .i"), {top:"70vw", duration:2,delay:0,ease: Power3.easeInOut,overwrite:true});
			gsap.to($("#loader #side-3 .i"), {left:"70vw", duration:2,delay:0,ease: Power3.easeInOut,overwrite:true});
		}

		
		initScroll();
			initMouseMove();
		
		setTimeout(function(){
			var winW = $(window).width();
			var winH = $(window).height();
			

			
			
			gsap.to($("#penrose"), {width:"400px", duration:2, delay:4.8, ease:Power3.easeInOut});
			//gsap.fromTo($("#penrose .triangle-side"),{scale:1}, {scale:1, duration:5, delay:0, ease:Power3.easeOut});
			gsap.fromTo($("#penrose .after"),{opacity:0}, {opacity:1, duration:2, delay:0, ease:Power3.easeInOut});
			setTimeout(function(){
				

				;
			},500); //2000
			
		},2500); //4000
	}



	
	

	
	
    var scrollPositionY = 0;
    var scrollPositionX = 0;
    var locoScroll;
	function initScroll(){
        var scrollPositionY;
        var scrollPositionX;
        
		locoScroll = new LocomotiveScroll({
			el: document.querySelector(".smooth-scroll"),
			smooth: true,
			smoothMobile: true,
			getDirection: true,
			smartphone: {
				smooth: true,
				lerp:0,
				multiplier: 2,
			},
			tablet: {
				smooth: true
			},

		});

		locoScroll.on("scroll", ScrollTrigger.update);
		//locoScroll.on("scroll", updateNavigation);
        var winW = $(window).width();
        var winH = $(window).height();
		locoScroll.on("scroll", scrollUpdate);
		
		locoScroll.on("scroll", textClippingAnimation);



		ScrollTrigger.scrollerProxy(".smooth-scroll", {
			scrollTop(value) {
				return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
			},
			getBoundingClientRect() {
				return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
			}
		});
		ScrollTrigger.addEventListener("refresh", () => locoScroll.update());


		initParallax();
    }

    



		
	
		
	function initSplitText(){
		
		var mySplitText = new SplitText("#intro-h1, #intro-h5,#intro-p", { type: "lines" });
		var mySplitText1 = new SplitText("#intro-h1, #intro-h5,#intro-p", { type: "lines" });

		var mySplitText2 = [];
		
		$('.text-box p').each(function(i,v){
			mySplitText2[i] = new SplitText($(v), { type: "lines",linesClass:"l l_++", wordsClass:"w w_++" });
		});
		var mySplitText3 = [];
		$('.text-box h2').each(function(i,v){
			mySplitText3[i] = new SplitText($(v), { type: "lines, chars",linesClass:"l l_++", charsClass:"c c_++" });
		});
	}





    function initParallax(){

		
			

    
		var i = 0;
		/*gsap.utils.toArray('h5#intro-h5>div>div, h1#intro-h1>div>div').forEach(box => {
			var trigger = $(box).parents("section")[0];
			var delay = 0+(i*0.0);
			

			gsap.to(box,
				{
					scrollTrigger: {
						//toggleActions: "restart pause resume complete",
						trigger: trigger,
						scroller: ".smooth-scroll",
						scrub: false,
						pin: false,
						start: "bottom 80%",
						end: "bottom 20%",
						markers: false,

					},
					y: "-100%",
					delay:0,
					duration:1.6,
					ease:Power3.easeOut,
				}
			);
			i++;
		});*/
		/*
		var i = 0;
		gsap.utils.toArray('h1#intro-h1>div>div').forEach(box => {
			var trigger = $(box).parents("section")[0];
			var delay = 1+(i*0.0);
			gsap.to(box,
				{
					scrollTrigger: {
						trigger: trigger,
						scroller: ".smooth-scroll",
						scrub: false,
						pin: false,
						start: "top 10%",
						end: "bottom 50%",
						markers: false
					},
					y: "-100%",
					opacity:1,
					duration:1,
					//scale:6,
					delay:delay,
					//"letter-spacing": "50px",
					ease:Power3.easeOut,
				}
			);
			i++;
		});*/

		
		/*var i = 0;
		gsap.utils.toArray('h5#intro-h5>div>div, h1#intro-h1>div>div, #intro-p>div>div').forEach(box => {
			var trigger = $(box).parents("section")[0];
			var delay = 0+(i*0.1);
			gsap.to(box,
				{
					scrollTrigger: {
						trigger: trigger,
						scroller: ".smooth-scroll",
						scrub: false,
						pin: false,
						start: "bottom 90%",
						end: "bottom 80%",
						markers: false,
						toggleActions: "play none play none"
					},
					y: "-100%",
					//opacity:0,
					duration:2,
					//scale:6,
					delay:delay,
					//"letter-spacing": "50px",
					ease:Power3.easeInOut,
				}
			);
			i++;
		}); */



		/*gsap.utils.toArray('.text-box p .w, .text-box h2 .c').forEach(box => {
			var i4 = $(box).attr("class").replace(/\D/g,'');
			var trigger = $(box).parent().parent();
			var delay = 0;
			if($(box).hasClass("w")){
				var delay = 0+(i4*0.06);
			}
			else{
				//var delay = 0+(i4*0.05);
			}
			gsap.fromTo(box,
				{
					y:"100%",
					opacity:1,
				},
				{
					scrollTrigger: {
						trigger: trigger,
						scroller: ".smooth-scroll",
						scrub: false,
						pin: false,
						start: "top bottom-=50px",
						end: "top 80%",
						markers: false,
						toggleActions: "play play none reverse",
					},
					y: "0%",
					delay: delay,
					opacity:1,
					duration:1,
					ease: Power3.easeOut,
				}
			);
		});*/


           



		gsap.utils.toArray('.work-item-title h3 .i2').forEach(box => {
	
			var trigger = $(box).parent();



			
			gsap.fromTo(box,
				{
					clipPath:"inset(0px 100% 0px 0px)",
				},
				{
					scrollTrigger: {
						trigger: trigger,
						scroller: ".smooth-scroll",
						scrub: true,
						pin: false,
						start: "top 90%",
						end: "top 50%",
						markers: false,
						toggleActions: "play none reverse none",
					},
					clipPath:"inset(0px 0% 0px 0px)",
					ease: Power1.easeInOut,
					immediateRender: true,
				}
			);
			
		});

		
       

            

        gsap.utils.toArray('.svg.shape svg').forEach(box => {
			var trigger = $(box).parents(".skill-hexagon")[0];
			var start2 = 80-(($(box).parent().prevAll().length)*8);
            var end2 = 40-(($(box).parent().prevAll().length)*4);
			gsap.to(box, {
				scrollTrigger: {
					trigger: trigger,
					scroller: ".smooth-scroll",
					scrub: true,
					pin: false,
					start: "top "+start2+"%",
					end: "top "+end2+"%",
					markers: false
				},
				y:"0%",
				scale: "1",
                rotate: "0deg",
                opacity: 1,
				ease: "power2.out"
			});
		});
		gsap.utils.toArray('.skill-hexagon .lable').forEach(box => {
			var trigger = $(box);
			var start2 = 70;
            var end2 = 40;
			gsap.to(box, {
				scrollTrigger: {
					trigger: trigger,
					scroller: ".smooth-scroll",
					scrub: false,
					pin: false,
					start: "top "+start2+"%",
					end: "top "+end2+"%",
					markers: false
				},
                opacity: 1,
				ease: "power2.out"
			});
		});

        gsap.utils.toArray('.svg#skill-1 svg path').forEach(box => {
			var trigger = $(box).parents(".skill-hexagon")[0];
			var start2 = 60;
            var end2 = 30;
            const path2 = "M188.5 459L136.5 230.5L360 41.5L584.5 230.5L531.5 459L360 619L188.5 459Z";
			gsap.to(box, {
				scrollTrigger: {
					trigger: trigger,
					scroller: ".smooth-scroll",
					scrub: true,
					pin: false,
					start: "top "+start2+"%",
					end: "top "+end2+"%",
					markers: false
				},
                attr: { d: path2 },
                opacity: 1,
				ease: "power2.out"
			});
		});
        gsap.utils.toArray('.svg#skill-2 svg path').forEach(box => {
			var trigger = $(box).parents(".skill-hexagon")[0];
			var start2 = 55;
            var end2 = 25;
            const path2 = "M360.563 162.033L584.45 231.25L636.379 519.307L360.45 619.229L189.063 459.08L136.249 230.557L360.563 162.033Z";
			gsap.to(box, {
				scrollTrigger: {
					trigger: trigger,
					scroller: ".smooth-scroll",
					scrub: true,
					pin: false,
					start: "top "+start2+"%",
					end: "top "+end2+"%",
					markers: false
				},
                attr: { d: path2 },
                opacity: 1,
				ease: "power2.out"
			});
		});





		gsap.utils.toArray('.hexagon-shape').forEach(box => {
			var trigger = $(".section-intro")[0];
            var start2 = 0;
            var end2 = 0;
			gsap.to(box,
				{
					scrollTrigger: {
						trigger: trigger,
						scroller: ".smooth-scroll",
						scrub: true,
						pin: false,
						start: "top "+start2+"%",
						end: "bottom "+end2+"%",
						markers: false,
						ease:"none"
					},
					y:"-280%",
					scale:0.5,
					//filter:"grayscale(70%)",
					opacity: 0.1,
					ease:"none"
					
				}
			);
		});
        

		


        gsap.utils.toArray('.work-item-image img').forEach(box => {
			var trigger = $(box).parents(".work-item-images")[0];
            var start2 = 100;
            var end2 = 0;
			gsap.to(box, {
				scrollTrigger: {
					trigger: trigger,
					scroller: ".smooth-scroll",
					scrub: true,
					pin: false,
					start: "top "+start2+"%",
					end: "bottom "+end2+"%",
					markers: false
				},
				y:"0%",
				scale: "1",
                rotate: "0deg",
                opacity: 1,
				ease: Power1.easeInOut
			});
		});
       gsap.utils.toArray('.work-item-image').forEach(box => {
			var trigger = $(box).parents(".work-item")[0];
            var prevAll = $(box).prevAll().length;
            var width = 33.33333;
            var xPerc = prevAll*width;
            var xPerc2 = xPerc+width;
			var start1 = 100-(prevAll*12);
            var end1 = 36-(prevAll*12);

            var start2 = 0-(prevAll*12); 
            var end2 = -64-(prevAll*12);

            gsap.fromTo(box,{
					"clip-path": "polygon("+xPerc+"% 0%, "+xPerc2+"% 0%, "+xPerc2+"% 100%, "+xPerc+"% 100%)",
					//scale:0.9,
				},
				{
					"clip-path": "polygon("+xPerc+"% 0%, "+xPerc2+"% 0%, "+xPerc2+"% 50%, "+xPerc+"% 50%)",
					//scale:0.6,
					immediateRender: true,
					ease: Power1.easeInOut,

					scrollTrigger: {
						toggleActions: "play none reverse none",
						trigger: trigger,
						scroller: ".smooth-scroll",
						scrub: true,
						pin: false,
						start: "top "+start2+"%",
						end: "top "+end2+"%",
						markers: false
					},

				}
			);

            gsap.fromTo(box,{
				    "clip-path": "polygon("+xPerc+"% 50%, "+xPerc2+"% 50%, "+xPerc2+"% 100%, "+xPerc+"% 100%)",
					//scale:0,
				},
				{
					"clip-path": "polygon("+xPerc+"% 0%, "+xPerc2+"% 0%, "+xPerc2+"% 100%, "+xPerc+"% 100%)",
					//scale:0.6,
					immediateRender: true,
					ease: Power1.easeInOut,

					scrollTrigger: {
                        toggleActions: "play none reverse none",
                        trigger: trigger,
                        scroller: ".smooth-scroll",
                        scrub: true,
                        pin: false,
                        start: "top "+start1+"%",
                        end: "top "+end1+"%",
                        markers: false
                    },

				}
			);
		});
    }



    


    function mouseMoveTriangle(e){
		var winW = $(window).width();
		var winH = $(window).height();
		var relPosX = e.pageX/winW;
		var relPosY = e.pageY/winH;

		/*$("#penrose .penrose-2 .triangle-img, .penrose-2 .after, .penrose-2").each(function(i,v){
			var parent = $(this).parent();
            var parentparent = $(parent).parent();
			var factor = 100;
			var pow = 0.9;
			var duration = 0.6;
            var elementPosX = 0.5;
            var elementPosY = 0.5;
			
			var relPosElementX = (relPosX-elementPosX); 
			var relPosElementY = (relPosY-elementPosY);

			var x = relPosElementX*factor;
			if(x < 0){
				var xEase = -Math.pow(-x, pow);
			}
			else{
				var xEase = Math.pow(x, pow);
			}
			var y = relPosElementY*factor;
			if(y < 0){
				var yEase = -Math.pow(-y, pow);
			}
			else{
				var yEase = Math.pow(y, pow);
			}

			if($(this).hasClass("penrose-2")){
				gsap.to($(this), {x:(xEase/2)+"px",y:(yEase/2)+"px", duration:duration, delay:0});
			}
			else{
				gsap.to($(this), {"x":xEase+"px", "y":yEase+"px", duration:duration, delay:0});
			}
			

            
		}); */
		
	}
	
	
	function mouseMoveCursor(e){
		var winW = $(window).width();
		var winH = $(window).height();
		var relPosX = e.pageX/winW;
		var relPosY = e.pageY/winH;
		gsap.to($("#cursor"), {x:e.pageX, y:e.pageY, duration:0.4,delay:0,ease: Power3.easeOut,overwrite:true});
		if(($(e.target).parents(".-cursor-view-project").length > 0) || ($(e.target).hasClass("-cursor-view-project"))){
			
			var stateName = "cursor-view-project";
			if(cursorState != stateName){
				toCursorState(stateName);
			}
			
		}
		else{

			var stateName = "default";
			if(cursorState != stateName){
				toCursorState(stateName);
			}
		}
	}

	function toCursorState(state){
		$(".cursor-overlay").removeAttr("data-state");
		$(".cursor-overlay").attr("data-state", state);
		cursorState = state;
		if(cursorState == "cursor-view-project"){
			$(".cursor-shape-1").addClass("-big");
			$(".cursor-shape-2").addClass("-big");
		}
		else{
			$(".cursor-shape-1").removeClass("-big");
			$(".cursor-shape-2").removeClass("-big");
		}
	}
	

	var currentMousePos = { pageY: -1, pageX: -1};
	var mouseDetected = false;
	var cursorState = "cursor-default";


	function initMouseMove(){
		$("body").on("mousemove", function(event) {
			currentMousePos = event;
			var winW = $(window).width();
			if(!mouseDetected){
				mouseDetected = true;
				$("html").addClass("-custom-cursor");
				
				
				
			}
			if(winW > 767){
				mouseMoveTriangle(currentMousePos);
				mouseMoveCursor(currentMousePos);
			}
			
		});
	}

    
	function textClippingAnimation(e){
		scrollPositionY = e.delta.y;
		var winW = $(window).width();
        var winH = $(window).height();
		
		$(".text-box .text").each(function(i,v){
			var o = {
				offsetTopToTop:$(v).offset().top,
				offsetTopToBottom:$(v).offset().top-winH,
				offsetBottomToTop:$(v).offset().top+$(v).outerHeight(),
				offsetBottomToBottom:$(v).offset().top-winH+$(v).outerHeight(),
				elHeight:$(v).outerHeight(),
				elWidth:$(v).outerWidth(),
				//rangeHeight: winH+$(v).outerHeight(),
				lines: $(".-layer2 .l", v).length
			};

			o.startAnimation = o.offsetTopToBottom+(winH/12);
			o.endAnimation =  o.offsetTopToTop-(winH/4);
			o.rangeHeight = winH+$(v).outerHeight();
			o.animationLength = o.endAnimation+(o.startAnimation*-1);
			o.relPerc = 1-(o.endAnimation/o.animationLength);
			o.relLinePerc = o.relPerc/o.lines;
			
			
			
			if((o.startAnimation < 0) && (o.endAnimation > 0)){
			
				

				$(".-layer2 .l", v).each(function(li,vi){
					//if(o.relPerc)
					var minPerc = li/o.lines;
					var maxPerc = (li+1)/o.lines;
					
					if((o.relPerc > minPerc) && (o.relPerc < maxPerc)){
						var linePerc = 100-(((o.relPerc-minPerc)*o.lines)*100);
						//console.log(li+1, linePerc);
						gsap.set($(vi), {clipPath:"inset(0px "+(linePerc)+"% 0px 0px)"});
					}
					else if(o.relPerc < minPerc){
						gsap.set($(vi), {clipPath:"inset(0px 100% 0px 0px)"});
					}
					else if(o.relPerc > maxPerc){
						gsap.set($(vi), {clipPath:"inset(0px 0% 0px 0px)"});
					}
					//
					//
				});
			}
			else if(o.startAnimation > 0){
				$(".-layer2 .l", v).each(function(li,vi){
					gsap.set($(vi), {clipPath:"inset(0px 100% 0px 0px)"});
				});
			}
			else if(o.endAnimation < 0){
				$(".-layer2 .l", v).each(function(li,vi){
					gsap.set($(vi), {clipPath:"inset(0px 0% 0px 0px)"});
				});
			}
			
			
		});
	}


    
    function scrollUpdate(e){
        
        scrollPositionY = e.delta.y;
		var winW = $(window).width();
        var winH = $(window).height();

		var scrollIndicator = $(".scroll-indicator-mouse");
		if(scrollPositionY == 0){
			gsap.set(scrollIndicator, {y:0});
		}
		else if((scrollPositionY > 0) && (scrollPositionY < 240)){
			var pos = (scrollPositionY)/5;
			gsap.set(scrollIndicator, {y:pos});
		}
		else if(scrollPositionY > 240){
			gsap.set(scrollIndicator, {y:"200px"});
		}
		
 

		var penroseEl = $("#penrose");
		var penroseStart = 40;
		var penroseEnd = penroseStart+(winH/2);
		if((scrollPositionY <= penroseStart) && (!$(penroseEl).hasClass("-scroll-animation-before"))){
			$(penroseEl).removeClass("-scroll-animation-in-progress").addClass("-scroll-animation-before");
			var penrosePos = 0;
			penroseAnimation(penrosePos);
		}
		else if((scrollPositionY > penroseStart) && (scrollPositionY < penroseEnd)){
			$(penroseEl).removeClass("-scroll-animation-after").removeClass("-scroll-animation-before");
			if(!$(penroseEl).hasClass("-scroll-animation-in-progress")){
				$(penroseEl).addClass("-scroll-animation-in-progress");
			}
			var zone = penroseEnd-penroseStart; //600 800-200
			var scrollPos = scrollPositionY-penroseStart;
			var penrosePos = scrollPos/zone;
			penroseAnimation(penrosePos);
		}
		else if((scrollPositionY >= penroseEnd) && (!$(penroseEl).hasClass("-scroll-animation-after"))){
			$(penroseEl).removeClass("-scroll-animation-in-progress").addClass("-scroll-animation-after");
			var penrosePos = 1;
			penroseAnimation(penrosePos);
		}




        
    }


	function penroseAnimation(pos){
		//console.log(pos);
		var penroseEl = $("#penrose");
		var penroseElInner = $(".penrose-2", penroseEl);
		var penroseElBg = $(".penrose-background", penroseEl);
		var penroseElImg = $(".triangle-img", penroseEl);
		var blur = pos*20;
		var blur = 0;
		var opacity = 1-(pos*0.4);
		var scale = 1-(pos/4);
		//gsap.set(penroseElInner, {"filter": "blur("+blur+"px)", "scale": scale});
		//gsap.set(penroseEl, {"opacity": opacity});
	}

	function changeActiveMenuItem(item){
		$("nav.side-nav ul li").removeClass("-active");
		var newActiveItem = $("nav.side-nav ul li a[href='"+item+"']").parent();
		$(newActiveItem).addClass("-active");
	}


	$("body").on("click", ".mobile-nav a", function(e){
        e.preventDefault();
		var link = $(this).attr("href");
		mobileNavClose();
		locoScroll.scrollTo(link, {'offset': -42});
		
		
    });


    $("body").on("click", "#js-toggle-mobile-nav", function(e){
        if($("body.-mobile-nav-open").length){
            mobileNavClose();
        }
        else{
            mobileNavOpen();
        }
    });

    function mobileNavOpen(){
        $("body").addClass("-mobile-nav-open");
		hamburgerState("open");


        const line = $(".mobile-nav-overlay .inner");
		gsap.to(line, {
			opacity:1,
			duration:1,
			delay:0,
			ease: Power3.easeOut
		});
		$(".mobile-nav li").each(function(i,v){
			gsap.to(v, {
				opacity:1,
				y:0,
				duration:1,
				delay:0.2+(i*0.1),
				ease: Power3.easeOut,
				overwrite:true
			});
		});
		
    }
    function mobileNavClose(){
        $("body").removeClass("-mobile-nav-open");
		hamburgerState("default");
		const line = $(".mobile-nav-overlay .inner");
        gsap.to(line, {scale:1, opacity:0,duration:1,ease: "Expo.easeInOut",overwrite:true});
		$(".mobile-nav li").each(function(i,v){
			gsap.to(v, {
				opacity:0,
				y:"30px",
				duration:0.5,
				delay:0.1-(i*0.04),
				ease: Power3.easeOut,
				overwrite:true
			});
		});
    }

	hamburgerState("default");
	
	
	
	function hamburgerState(state){
		if(state == "default"){
			gsap.to($(".hamburger-icon #line-1 .i"), {y:-6, rotate:0, opacity:1,ease: Power3.easeOut,overwrite:true});
			gsap.to($(".hamburger-icon #line-2 .i"), {opacity:1,ease: Power3.easeOut,overwrite:true});
			gsap.to($(".hamburger-icon #line-3 .i"), {y:+6,rotate:0, opacity:1,ease: Power3.easeOut,overwrite:true});
		}
		if(state == "open"){
			gsap.to($(".hamburger-icon #line-1 .i"), {y:0, rotate:45,opacity:1,ease: Power3.easeOut,overwrite:true});
			gsap.to($(".hamburger-icon #line-2 .i"), {opacity:0,ease: Power3.easeOut,overwrite:true});
			gsap.to($(".hamburger-icon #line-3 .i"), {y:0,rotate:-45,opacity:1,ease: Power3.easeOut,overwrite:true});
		}
	}



	



	function hexagonAnimation(el){
		$(".axe", el).each(function(i,v){
			$(".mask", v).each(function(i2, v2){
				gsap.to($(v2), {opacity:1, delay:0+(i*.10)+((i2*0.1)), ease: "Expo.easeOut", duration:2});
				
				gsap.to($(".line",v2), 
					{
						y:0,
						delay:1+(i*.10)+((i2*0.1)),
						ease: "Expo.easeOut",
						duration:2
					}
				);
				gsap.fromTo($(".r, .l",v2), 
					{
						"width": "0px",
						"height": "1px"
					},
					{
						"width": "44px",
						"height": "1px", 
						delay:1+(i*.10)+((i2*0.1)),
						ease: "Expo.easeOut",
						duration:2
					}
				);
			});
		});
		
		gsap.to($(".hexagon-shape .inner"), {rotate:"0deg", scale:"1", opacity:1, delay:1, duration:3,ease: "Expo.easeOut"});
	}

	var el = $(".hexagon-shape");
	hexagonAnimation(el);




	pageInit();


} ) ( jQuery );







